@import "../../../styles/vars";

.button {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    min-width: 200px;
    width: auto;
    line-height: 40px;
    text-align: center;
    border-radius: 24px;
    border: $red solid 1px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.05em;
    color: $white;
    background-color: $red;
    font-style: italic;
    text-decoration: none;
    padding: 0 16px;
    transition: color .2s $QuadEaseInOut;
    overflow: hidden;
    z-index: 2;

    svg {
        path {
            transition: fill .2s $QuadEaseInOut;
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        background-color: #fff;
        width: calc( 100% );
        height: calc( 100% );
        transition: transform .2s $QuadEaseInOut;
        z-index: -1;
        border-radius: 24px;
    }

    &:hover {
        color: $red !important;

        &::before {
            transform: translate(-50%, -50%) scale(1);
        }
    }

    &:active {
        transform: scale(0.97);
    }

    &.orange {
        background-color: $orange;
        border: $orange solid 1px;

        &:hover {
            color: $orange !important;

            svg {
                path {
                    fill: $orange;
                }
            }
        }
    }

    &.orange-transparent {
        border: $orange solid 1px;
        background-color: transparent;
        color: $orange;

        &::before {
            background-color: $orange;
        }

        &:hover {
            color: #fff !important;
        }
    }

    &.red-transparent {
        border: $red solid 1px;
        background-color: transparent;
        color: $red;

        &::before {
            background-color: $red;
        }

        &:hover {
            color: #fff !important;
        }
    }

    &.white-transparent {
        border: #fff solid 1px !important;
        background-color: transparent;
        color: #fff;
    }
    
    &.blue {
        background-color: $blue;
        border: $blue solid 1px;

        &:before {
            background-color: #fff;
        }

        &:hover {
            color: $blue !important;
        }
    }

    &.white-blue {
        background-color: #fff;
        color: $blue;
        border: #fff solid 1px;

        &:before {
            background-color: $blue;
        }

        &:hover {
            color: #fff !important;
        }
    }

    &.disabled {
        opacity: 0.5;
    }

    &.disabled {
        opacity: 0.5;
    }
}