@import "../../../styles/vars";

.not-found {
    position: relative;
    min-height: calc( 100vh - 170px );
    text-align: center;
    z-index: 2;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        left: -25vw;
        top: -260px;
        width: 150vw;
        height: 600px;
        background: rgba(246, 246, 246, 0.9);
        box-shadow: 8px 16px 32px rgba(0, 0, 0, 0.12);
        transform: rotate(-16deg);
        z-index: -1;

        @include HD {
            top: -760px;
            height: 1200px;
        }

        @include FHD {
            top: -660px;
        }
    }

    &-inner {
        @include XGA {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            margin-top: 80px;
        }

        @include HD {
            margin-top: 24px;
        }

        @include FHD {
            margin-top: 40px;
        }

        .text {
            @include XGA {
                text-align: left;
                margin-left: 6vw;
            }
        }

        h1 {
            text-align: center;
            font-size: 160px;
            line-height: 160px;
            font-weight: 900;
            color: rgba(225, 125, 85, 0.15);
            margin: 0;
            margin-top: 30px;

            @include XGA {
                position: relative;
                font-size: 30vw;
                line-height: 30vw;
                margin: 0;
                text-align: left;
                margin-left: -3vw;
                margin-top: 4vw;
            }
        }

        h2 {
            font-size: 32px;
            line-height: 36px;
            font-weight: 900;
            text-transform: uppercase;
            margin: 0;

            span {
                color: #E17D55;
            }

            @include XGA {
                font-size: 48px;
                line-height: 56px;
            }
        }

        p {
            font-size: 15px;
            line-height: 20px;
            font-weight: 600;

            @include XGA {
                font-size: 24px;
                line-height: 36px;
            }

            strong {
                font-weight: 800;
            }
        }

        .button {
            margin: 32px 0;

            @include XGA {
                margin-bottom: 0;
            }
        }
    }
}