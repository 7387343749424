@import "../../../styles/vars";

.modal {
  &-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba($color: black, $alpha: .85);
    z-index: 1100;

    &.age-gate-overlay {
      z-index: 9999;
    }
  }

  &-inner {
    position: absolute;
    max-width: 720px;
    max-height: 90%;
    width: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(255, 255, 255);
    overflow: auto;
    outline: none;
    border: none;
    border-radius: 12px;

    .close-modal {
      background: none;
      border: none;
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      svg {
        path {
          fill: $black;
        }
      }
    }

    .upper-bar {
      min-height: 80px;
      background-color: #E5451F;
      display: grid;
      padding: 16px;

      @include XGA {
        min-height: 88px;
        padding: 24px;
      }

      * {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      img {
        place-self: center;
        max-width: 40px;
        max-height: 40px;
      }

      button {
        width: fit-content;
        height: fit-content;
        align-self: top;
        justify-self: right;
      }
    }

    .inner {
      padding: 24px;

      .button {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}