// ------------------------------------------------
// Site Name:
// Author:
// ------------------------------------------------

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

:root {
	color-scheme: light;
}

body,
html {
	margin: 0 auto;
}

body {
	font-family: $main;
	overflow: hidden;
}

.app {
	position: relative;
    height: 100vh;
    overflow-x: hidden;
}

ul {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
	}
}

h1, h2, h3, h4, h5, h6, p, li, input, label {
	color: $black;
}

input {
	font-size: 16px;
}

p {
	font-size: 15px;
}

section {
	position: relative;
	padding: 30px 24px;

	@include XGA {
		padding: 36px 5vw;
	}
}

.chip {
	display: inline-block;
	position: relative;
	margin: 0 auto;
	min-width: 80px;
	width: auto;
	line-height: 1em;
	text-align: center;
	border-radius: 24px;
	border: none;
	cursor: pointer;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 400;
	color: $black;
	border: 1px solid $black;
	text-decoration: none;
	padding: 6px 10px 3px;

	&:active {
			transform: scale(0.97);
	}

	&.active {
		background-color: $red;
		border: 1px solid $red;
		color: $white;
	}

	&.orange {
			background-color: $orange;
	}
	
	&.blue {
		&.active {
			&.active {
				background-color: $blue;
				border: 1px solid $blue;
			}
		}
	}
}

.back-button {
	position: absolute;
	left: 24px;
	top: 30px;
	cursor: pointer;

	@include HD {
		left: 80px;
	}
}