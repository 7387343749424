@import "../../../../styles/vars";

.reset-password, .logout-success, .register-success, .age-gate, .cookie-options, .confirm-success, .confirm-error {
  text-align: center;
  padding: 36px !important;
  padding-top: 48px !important;

  p {
    font-size: 15px;
    line-height: 20px;

    &.disclaimer {
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 14px;
      text-align: left;
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      margin-bottom: 12px;

      &::before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: $blue;
        border-radius: 50%;
      }
    }
  }

  img {
    margin: 16px 0;
  }

  .small {
    font-size: 12px;
  }

  .button {
    margin-top: 24px;
  }

  h2 {
    margin-top: 0;
    text-transform: uppercase;
    font-weight: 800;
  }

  &.register-success {
    h2 {
      span {
        white-space: nowrap;
      }
    }
  }
}

.age-gate {
  padding-inline: 0 !important;
  
  h4 {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 21px;
    line-height: 25px;
    margin: 0;
    margin-bottom: 16px;
    padding-inline: 24px;
  }

  p {
    font-size: 10px;
    line-height: 12px;
    margin: 0;
    margin-top: 30px;
  }

  .disclaimer {
    margin: 0 auto;
    margin-top: 30px;
    max-width: 80%;
    text-align: center !important;
  }

  picture:first-of-type {
    img {
      margin: 0 0 24px;
    }
  }

  picture:last-of-type {
    img {
      margin: 30px 0 0;
    }
  }

  .btn-holder {
    margin: 0 auto;
    max-width: 290px;
    
    @include vHD {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: center;
      column-gap: 24px;
    }

    .button {
      width: auto;
      padding: 0 16px;
      white-space: nowrap;
    }
  }
}

.cookie-options {
  padding: 0 !important;

  @include HD {
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 380px;
    min-width: 720px;
  }

  .cookies-top {
    background-color: #ECECEC;
    padding: 24px;

    @include HD {
      text-align: left;
      padding: 64px 32px;
      align-self: stretch;
      min-width: 250px;
    }

    h2 {
      font-size: 18px;
      line-height: 24px;
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
      margin-bottom: 16px;
    }

    .cookies-nav {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;

      @include XGA {
        display: block;
      }

      li {
        border: 1px solid #2A2C2C;
        border-radius: 24px;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 16px;
        padding: 2px 8px;
        cursor: pointer;
        font-weight: 700;
        
        &::before {
          display: none;
        }

        @include HD {
          display: inline-block;
          margin-bottom: 12px;
        }

        &.active {
          background-color: $orange;
          color: #fff;
          border-color: $orange;
        }
      }
    }
  }

  .cookies-content {
    padding: 24px;

    @include HD {
      text-align: left;
      padding: 48px 32px;
    }
    
    h3 {
      font-size: 21px;
      font-weight: 800;
      line-height: 25px;
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 16px;

      @include HD {
        font-size: 24px;
        line-height: 32px;
      }

      span {
        color: $red;
      }
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      margin: 0;
    }

    .button {
      @include HD {
        margin-left: 0;
      }
    }

    .switch-outer {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      column-gap: 12px;
      margin-top: 16px;
      margin-bottom: 32px;
      cursor: pointer;

      @include HD {
        justify-content: flex-start;
      }

      .switch {
        position: relative;
        width: 28px;
        height: 16px;
        border-radius: 9px;
        background-color: $gray;

        &::before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: 2px;
          top: 2px;
          background-color: #fff;
          border-radius: 50%;
          transition: left .3s $CubicEaseInOut;
        }

        &.active {
          background-color: $orange;

          &::before {
            left: 14px;
          }
        }
      }

      p {
        margin: 0;
        font-size: 12px;
        line-height: 16px;
        font-weight: 800;
      }
    }
  }

  .cookie-main {
    padding: 48px 24px;

    @include XGA {
      padding: 64px 80px;
    }

    p {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;

      @include XGA {
        font-size: 15px;
        line-height: 20px;
      }

      a {
        color: $black;
      }
    }

    .buttons {
      margin-top: 24px;

      @include vHD {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        justify-content: center;
        column-gap: 12px;
      }

      .button {
        margin: 0 auto;
        margin-top: 12px;

        @include vHD {
          margin: 0;
        }
      }
    }
  }
}

.program-finished-overlay {
  .modal-inner {
    overflow: initial;
  }

  .program-finished {
    padding: 0;

    @include XGA {
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    .top {
      height: 130px;
      background-size: cover;
      background-position: center;
      background-image: url(./img/finish-top-mobile.jpg);
      padding-top: 64px;
      padding-left: 26px;
      border-radius: 12px 12px 0px 0px;

      .close-modal {
        position: absolute;
        top: -10px;
        right: -10px;
      }

      @include XGA {
        background-image: url(./img/finish-top-desktop.jpg);
        height: auto;
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        padding-top: 260px;
        padding-left: 26px;
        border-radius: 12px 0px 0px 12px;
      }

      h3 {
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        margin: 0;
        color: #fff;
        font-style: italic;
        text-transform: uppercase;

        @include XGA {
          font-size: 25px;
          line-height: 28px;
        }

        span {
          display: inline-block;
          background-color: #22BA7A;
          padding: 3px 5px;

          @include XGA {
            padding: 5px 8px;
          }

          &.bold {
            font-weight: 900;
          }
        }
      }
    }

    .content { 
      padding: 24px 16px;

      @include XGA {
        width: 100%;
        padding: 28px 48px;
      }

      h3 {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-weight: 900;
        line-height: 130%;
        font-style: italic;
        margin: 0;

        @include XGA {
          text-align: left;
          margin-left: 13px;
        }

        span {
          font-weight: 500;
          font-style: initial;
        }
      }

      ul {
        margin: 0;
        margin-top: 24px;

        li {
          position: relative;
          padding-left: 44px;
          color: #446082;
          font-size: 12px;
          font-style: italic;
          font-weight: 700;
          line-height: 130%;
          margin-bottom: 16px;

          span {
            position: absolute;
            left: 0;
            top: 0;
            width: 26px;
            height: 26px;
            border: #22BA7A solid 1px;
            border-radius: 50%;
            line-height: 26px;
            text-align: center;
            color: #22BA7A;
          }
        }
      }
    }
  }
}