@import "../../../styles/vars";

footer {
    display: block;
    position: relative;
    padding-bottom: 24px;
    z-index: 10;

    p,
    h4 {
        margin: 0;
    }

    @include XGA {
        margin-bottom: 0;
        // padding-top: 48px;
        padding-bottom: 1px;
    }

    @include FHD {
        max-width: 1920px;
        margin-inline: auto;
    }

    .line {
        border-color: #868686;
        opacity: 0.3;
        margin-top: 16px;
        margin-bottom: 24px;

        @include XGA {
            display: none;
        }
    }

    .footer-top {
        background-color: #2A2C2C;
        padding: 24px;
        position: relative;

        @include XGA {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 64px;
        }

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            background-color: #2A2C2C;
            z-index: -1;
        }

        .over-18 {
            display: flex;
            flex-flow: column;
            gap: 20px;
            align-items: flex-start;

            @include XGA {
                flex-flow: row;
                gap: 28px;
                align-items: center;
            }

            .logo {
                width: 104px;
            }

            p {
                max-width: 248px;

                @include XGA {
                    font-size: 10px;
                    line-height: 15px;
                    max-width: initial;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    color: #C6C6C5;
                }
            }
        }

        .footer-left {
            @include XGA {
                display: flex;
                flex-flow: column-reverse;
            }
        }

        h4,
        p,
        a,
        span {
            color: white;
        }

        p,
        a,
        span {
            font-weight: 500;
            font-size: 10px;
            line-height: 14px;
        }

        a {
            text-decoration: none;
            font-weight: 600;
        }

        h4 {
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
        }

        .footer-contact {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            justify-content: space-between;

            .social {
                width: 100%;

                @include XGA {
                    display: flex;
                    align-items: center;
                }

                .wrapper {
                    width: 100%;
                    display: flex;
                    gap: 24px;
                    align-items: center;

                    div {
                        svg {
                            display: block;
                        }
                    }

                    div:last-of-type {
                        margin-left: -8px;
                    }
                }
            }

            h4 {
                margin-bottom: 8px;

                @include XGA {
                    margin: 0;
                    min-width: 108px;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;
                    text-align: right;
                    color: #C6C6C5;
                    margin-right: 32px;
                }
            }

            p,
            a {
                font-size: 12px;
                text-decoration: underline;

                @include XGA {
                    font-size: 12px;
                }
            }

            .email {
                margin: 0;
                font-weight: 600;
                color: #F7F7F7;

                @include XGA {
                    color: white;
                }
            }
        }
    }

    .footer-nav {
        background: #E5E5E5;
        padding: 24px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            background: #E5E5E5;
            z-index: -1;
        }

        @include XGA {
            padding: 16px 64px 16px;
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 16px;

            @include XGA {
                flex-shrink: 0;
            }

            li {
                span {
                    text-decoration: none;
                    cursor: pointer;
                }

                a,
                span {
                    white-space: nowrap;
                    color: #2A2C2C;
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 20px;
                    text-decoration-line: underline;

                    @include XGA {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }

    .flex-bat-logo {
        display: flex;
        gap: 28px;
        align-items: flex-end;
        margin-bottom: 16px;

        @include XGA {
            margin: 0;
        }

        img.bat-logo {
            width: 54px;
        }

        p.copyrights {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #2A2C2C;
            margin: 0;
        }

        &.mobile {
            @include XGA {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @include XGA {
                display: flex;
            }
        }
    }

    .footer-bottom {
        padding: 24px;
        background-color: white;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            display: block;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100vw;
            height: 100%;
            background-color: white;
            z-index: -1;
        }

        picture {
            filter: invert(1) brightness(1);
        }

        @include XGA {
            padding: 24px 64px 24px;
        }

        p {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
            margin: 0;
        }
    }

    .br-desktop {
        display: none;

        @include XGA {
            display: block;
        }
    }
}